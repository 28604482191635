import React, { Component } from 'react';
import '../carousel.css';
let d1 = "https://fishpricsassets.pages.dev/assets/1.jpg";
let d2 = "https://fishpricsassets.pages.dev/assets/2.jpg";
let d3 = "https://fishpricsassets.pages.dev/assets/3.jpg";
let d4 = "https://fishpricsassets.pages.dev/assets/4.jpg";
let d5 = "https://fishpricsassets.pages.dev/assets/5.jpg";
let d6 = "https://fishpricsassets.pages.dev/assets/6.jpg";
let d7 = "https://fishpricsassets.pages.dev/assets/7.jpg";
let d8 = "https://fishpricsassets.pages.dev/assets/8.jpg";
let d9 = "https://fishpricsassets.pages.dev/assets/9.jpg";
let d10 = "https://fishpricsassets.pages.dev/assets/10.jpg";

class Carousel extends Component { 

    render() {
        return (

            <div class="boxWrapC">

                <div class="slider">
                    <div class="slide-track2">
                        <div class="slide">
                            <img class="carouselIMG" src={d1} alt="d5" />
                        </div>
                        <div class="slide">
                            <img class="carouselIMG" src={d2} alt="d5" />
                        </div>
                        <div class="slide">
                            <img class="carouselIMG" src={d3} alt="d5" />
                        </div>
                        <div class="slide">
                            <img class="carouselIMG" src={d4} alt="d5" />
                        </div>
                        <div class="slide">
                            <img class="carouselIMG" src={d5} alt="d5" />
                        </div>
                        <div class="slide">
                            <img class="carouselIMG" src={d6} alt="d5" />
                        </div>
                        <div class="slide">
                            <img class="carouselIMG" src={d7} alt="d5" />
                        </div>
                        <div class="slide">
                            <img class="carouselIMG" src={d8} alt="d5" />
                        </div>
                        <div class="slide">
                            <img class="carouselIMG" src={d9} alt="d5" />
                        </div>
                        <div class="slide">
                            <img class="carouselIMG" src={d10} alt="d5" />
                        </div>
                     
                    </div>
                </div>

                <div class="slider2">
                    <div class="slide-track3">
                    <div class="slide">
                            <img class="carouselIMG" src={d10} alt="d5" />
                        </div>
                        <div class="slide">
                            <img class="carouselIMG" src={d9} alt="d5" />
                        </div>
                        <div class="slide">
                            <img class="carouselIMG" src={d8} alt="d5" />
                        </div>
                        <div class="slide">
                            <img class="carouselIMG" src={d7} alt="d5" />
                        </div>
                        <div class="slide">
                            <img class="carouselIMG" src={d6} alt="d5" />
                        </div>
                        <div class="slide">
                            <img class="carouselIMG" src={d5} alt="d5" />
                        </div>
                        <div class="slide">
                            <img class="carouselIMG" src={d4} alt="d5" />
                        </div>
                        <div class="slide">
                            <img class="carouselIMG" src={d3} alt="d5" />
                        </div>
                        <div class="slide">
                            <img class="carouselIMG" src={d2} alt="d5" />
                        </div>
                        <div class="slide">
                            <img class="carouselIMG" src={d1} alt="d5" />
                        </div>
                    </div>
                </div>

            </div>
        );
    }
}

export default Carousel;        