import './App.css';
import React, { Component } from 'react';
import { SocialIcon } from 'react-social-icons';
import Story from "./components/story";
import Roadmap from './components/roadmap';
//import nft from './assets/Mintanimation.webm';
import Web3 from "web3";
import Web3Modal from "web3modal";
import WalletConnectProvider from "@walletconnect/web3-provider";
import './drippy.css';
import os from './assets/opensea.png';
import Carousel from './components/carousel';
import nft from './assets/3.jpg';

var Scroll = require('react-scroll');

var Link = Scroll.Link;
var DirectLink = Scroll.DirectLink;
var Element = Scroll.Element;
var Events = Scroll.Events;
var scroll = Scroll.animateScroll;
var scrollSpy = Scroll.scrollSpy;

const opensea = () => {
	window.open("https://opensea.io/collection/fishpricks");
}

/*
<div class="homeImages">
								<div class="homeImages1"><img class="rightPic" src={left} /></div>
								<div class="homeImages2"><img class="rightPic" src={right} /></div>
							</div>
*/
let account;
let mintAmount = 1;
let valueOfNFTs = 0;
let totalSupplyNFT;
let totalSupplyNFT2;
let maxMintNFTs;
let onlyLeft;
let owner;
let publicSale;
let user_input;
let wMintAmount;
let myTokens = [];
let myTokens2 = [];
let adminWhitelistAddresses = [];
let adminPanel = 0;
let adminWhitelistAddresses2 = [];
let maxTokensToBuy = "";
let testingvalue;
let wlMint;
let FinalResult;
let wlMsg = "Whitelist Mint";
let public_sale_cost;
let presalesale_cost;
let MAX_PER_WALLET;
let public_mint_status = false;
let preSale_mint_status = false
let preSaleLimit;
let preSaleCost;
let cost;
let preSaleCount;
let video = "https://fishpricsassets.pages.dev/assets/fishh.mp4";
let logomiddle = 'https://fishpricsassets.pages.dev/assets/main_title.png';
let mintH = 'https://fishpricsassets.pages.dev/assets/minting.png';

const ABI = [
	{
		"inputs": [
			{
				"internalType": "string",
				"name": "_initBaseURI",
				"type": "string"
			},
			{
				"internalType": "string",
				"name": "_initNotRevealedUri",
				"type": "string"
			},
			{
				"internalType": "string",
				"name": "_contractURI",
				"type": "string"
			}
		],
		"stateMutability": "nonpayable",
		"type": "constructor"
	},
	{
		"inputs": [],
		"name": "ApprovalCallerNotOwnerNorApproved",
		"type": "error"
	},
	{
		"inputs": [],
		"name": "ApprovalQueryForNonexistentToken",
		"type": "error"
	},
	{
		"inputs": [],
		"name": "ApprovalToCurrentOwner",
		"type": "error"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "to",
				"type": "address"
			},
			{
				"internalType": "uint256",
				"name": "tokenId",
				"type": "uint256"
			}
		],
		"name": "approve",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "ApproveToCaller",
		"type": "error"
	},
	{
		"inputs": [],
		"name": "BalanceQueryForZeroAddress",
		"type": "error"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "quantity",
				"type": "uint256"
			}
		],
		"name": "mint",
		"outputs": [],
		"stateMutability": "payable",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "MintToZeroAddress",
		"type": "error"
	},
	{
		"inputs": [],
		"name": "MintZeroQuantity",
		"type": "error"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "operator",
				"type": "address"
			}
		],
		"name": "OperatorNotAllowed",
		"type": "error"
	},
	{
		"inputs": [],
		"name": "OwnerIndexOutOfBounds",
		"type": "error"
	},
	{
		"inputs": [],
		"name": "OwnerQueryForNonexistentToken",
		"type": "error"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "quantity",
				"type": "uint256"
			}
		],
		"name": "preSale",
		"outputs": [],
		"stateMutability": "payable",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "TokenIndexOutOfBounds",
		"type": "error"
	},
	{
		"inputs": [],
		"name": "TransferCallerNotOwnerNorApproved",
		"type": "error"
	},
	{
		"inputs": [],
		"name": "TransferFromIncorrectOwner",
		"type": "error"
	},
	{
		"inputs": [],
		"name": "TransferToNonERC721ReceiverImplementer",
		"type": "error"
	},
	{
		"inputs": [],
		"name": "TransferToZeroAddress",
		"type": "error"
	},
	{
		"inputs": [],
		"name": "URIQueryForNonexistentToken",
		"type": "error"
	},
	{
		"anonymous": false,
		"inputs": [
			{
				"indexed": true,
				"internalType": "address",
				"name": "owner",
				"type": "address"
			},
			{
				"indexed": true,
				"internalType": "address",
				"name": "approved",
				"type": "address"
			},
			{
				"indexed": true,
				"internalType": "uint256",
				"name": "tokenId",
				"type": "uint256"
			}
		],
		"name": "Approval",
		"type": "event"
	},
	{
		"anonymous": false,
		"inputs": [
			{
				"indexed": true,
				"internalType": "address",
				"name": "owner",
				"type": "address"
			},
			{
				"indexed": true,
				"internalType": "address",
				"name": "operator",
				"type": "address"
			},
			{
				"indexed": false,
				"internalType": "bool",
				"name": "approved",
				"type": "bool"
			}
		],
		"name": "ApprovalForAll",
		"type": "event"
	},
	{
		"anonymous": false,
		"inputs": [
			{
				"indexed": true,
				"internalType": "address",
				"name": "previousOwner",
				"type": "address"
			},
			{
				"indexed": true,
				"internalType": "address",
				"name": "newOwner",
				"type": "address"
			}
		],
		"name": "OwnershipTransferred",
		"type": "event"
	},
	{
		"inputs": [],
		"name": "renounceOwnership",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "from",
				"type": "address"
			},
			{
				"internalType": "address",
				"name": "to",
				"type": "address"
			},
			{
				"internalType": "uint256",
				"name": "tokenId",
				"type": "uint256"
			}
		],
		"name": "safeTransferFrom",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "from",
				"type": "address"
			},
			{
				"internalType": "address",
				"name": "to",
				"type": "address"
			},
			{
				"internalType": "uint256",
				"name": "tokenId",
				"type": "uint256"
			},
			{
				"internalType": "bytes",
				"name": "data",
				"type": "bytes"
			}
		],
		"name": "safeTransferFrom",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "operator",
				"type": "address"
			},
			{
				"internalType": "bool",
				"name": "approved",
				"type": "bool"
			}
		],
		"name": "setApprovalForAll",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "string",
				"name": "_newBaseURI",
				"type": "string"
			}
		],
		"name": "setBaseURI",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "string",
				"name": "_contractURI",
				"type": "string"
			}
		],
		"name": "setContractURI",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "_max_per_wallet",
				"type": "uint256"
			}
		],
		"name": "setMax_per_wallet",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "_MAX_SUPPLY",
				"type": "uint256"
			}
		],
		"name": "setMAX_SUPPLY",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "string",
				"name": "_notRevealedURI",
				"type": "string"
			}
		],
		"name": "setNotRevealedURI",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "_preSaleCost",
				"type": "uint256"
			}
		],
		"name": "setPreSaleCost",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "_preSaleCount",
				"type": "uint256"
			}
		],
		"name": "setPreSaleCount",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "_preSaleLimit",
				"type": "uint256"
			}
		],
		"name": "setPreSaleLimit",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "_publicSaleCost",
				"type": "uint256"
			}
		],
		"name": "setPublicSaleCost",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "_receiver",
				"type": "address"
			},
			{
				"internalType": "uint96",
				"name": "_royaltyFeesInBips",
				"type": "uint96"
			}
		],
		"name": "setRoyaltyInfo",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "toggle_preSale_mint_status",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "toggle_public_mint_status",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "toggleReveal",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"anonymous": false,
		"inputs": [
			{
				"indexed": true,
				"internalType": "address",
				"name": "from",
				"type": "address"
			},
			{
				"indexed": true,
				"internalType": "address",
				"name": "to",
				"type": "address"
			},
			{
				"indexed": true,
				"internalType": "uint256",
				"name": "tokenId",
				"type": "uint256"
			}
		],
		"name": "Transfer",
		"type": "event"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "from",
				"type": "address"
			},
			{
				"internalType": "address",
				"name": "to",
				"type": "address"
			},
			{
				"internalType": "uint256",
				"name": "tokenId",
				"type": "uint256"
			}
		],
		"name": "transferFrom",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "newOwner",
				"type": "address"
			}
		],
		"name": "transferOwnership",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "withdraw",
		"outputs": [],
		"stateMutability": "payable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "owner",
				"type": "address"
			}
		],
		"name": "balanceOf",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "baseURI",
		"outputs": [
			{
				"internalType": "string",
				"name": "",
				"type": "string"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "contractURI",
		"outputs": [
			{
				"internalType": "string",
				"name": "",
				"type": "string"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "tokenId",
				"type": "uint256"
			}
		],
		"name": "getApproved",
		"outputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "owner",
				"type": "address"
			},
			{
				"internalType": "address",
				"name": "operator",
				"type": "address"
			}
		],
		"name": "isApprovedForAll",
		"outputs": [
			{
				"internalType": "bool",
				"name": "",
				"type": "bool"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "max_per_wallet",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "name",
		"outputs": [
			{
				"internalType": "string",
				"name": "",
				"type": "string"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "notRevealedUri",
		"outputs": [
			{
				"internalType": "string",
				"name": "",
				"type": "string"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "owner",
		"outputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "tokenId",
				"type": "uint256"
			}
		],
		"name": "ownerOf",
		"outputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "preSale_mint_status",
		"outputs": [
			{
				"internalType": "bool",
				"name": "",
				"type": "bool"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "preSaleCost",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "preSaleCount",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "preSaleLimit",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "public_mint_status",
		"outputs": [
			{
				"internalType": "bool",
				"name": "",
				"type": "bool"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "publicSaleCost",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "revealed",
		"outputs": [
			{
				"internalType": "bool",
				"name": "",
				"type": "bool"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "_tokenId",
				"type": "uint256"
			},
			{
				"internalType": "uint256",
				"name": "_salePrice",
				"type": "uint256"
			}
		],
		"name": "royaltyInfo",
		"outputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			},
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "bytes4",
				"name": "interfaceId",
				"type": "bytes4"
			}
		],
		"name": "supportsInterface",
		"outputs": [
			{
				"internalType": "bool",
				"name": "",
				"type": "bool"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "symbol",
		"outputs": [
			{
				"internalType": "string",
				"name": "",
				"type": "string"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "index",
				"type": "uint256"
			}
		],
		"name": "tokenByIndex",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "owner",
				"type": "address"
			},
			{
				"internalType": "uint256",
				"name": "index",
				"type": "uint256"
			}
		],
		"name": "tokenOfOwnerByIndex",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "tokenId",
				"type": "uint256"
			}
		],
		"name": "tokenURI",
		"outputs": [
			{
				"internalType": "string",
				"name": "",
				"type": "string"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "totalSupply",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	}
];

const address = "0xBc84F42cB2105cD46d941Ab81Aa13E3CBec79C67";
let contract;

class App extends Component {

	state = {
		walletAddress: "",
		totalSupply: "",
		currentPrice: "",
		nextPrice: "",
		nextSessionAmount: "",
		onlyLeftAmount: "",
		statusError: false,
		statusLoading: false,
		success: false,
		nftMintingAmount: '1',
		totalValue: "",
		presaleValue: "0",
		maxmint: '',
		_adminPanel: 0,
		_adminWhitelistAddresses: [],
		_adminWhitelistAddresses2: [],
		_maxTokensToBuy: "",
		_testingValue: '',
		_wlMint: '',
		_FinalResult: '',
		_wlMsg: 'Whitelist Mint',
		_public_sale_cost: '',
		_presalesale_cost: '',
		_MAX_PER_WALLET: '',
		_public_mint_status: '',
		_preSale_mint_status: '',
		_cost: '',
		_mintStatus: false,
		_preSaleCost: '',
		_preSaleLimit: '',
		_preSaleCount: ''
	}

	onSubmitMinus = async event => {
		event.preventDefault();

		mintAmount = mintAmount - 1;

		if (mintAmount < 1) {
			mintAmount = 1
		}


		if (owner == account) {
			console.log("owner : " + owner)
			onlyLeft = 1000 - totalSupplyNFT;

			if (mintAmount > onlyLeft) {
				mintAmount = onlyLeft;
			}

			valueOfNFTs = mintAmount * 0;
			wMintAmount = mintAmount;

			this.setState({ nftMintingAmount: mintAmount });

			this.setState({ totalValue: valueOfNFTs });
		} else {


			if (totalSupplyNFT < 1000) {

				onlyLeft = MAX_PER_WALLET - publicSale;

				if (mintAmount > onlyLeft) {
					mintAmount = onlyLeft;
				}
				valueOfNFTs = mintAmount * this.state._cost;
				wMintAmount = mintAmount;


				this.setState({ nftMintingAmount: mintAmount });

				this.setState({ totalValue: valueOfNFTs });

			}
		}
	}

	onSubmitPlus = async event => {
		event.preventDefault();

		//,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,
		mintAmount = mintAmount + 1;

		if (owner == account) {
			console.log("owner : " + owner)
			onlyLeft = 1000 - totalSupplyNFT;

			if (mintAmount > onlyLeft) {
				mintAmount = onlyLeft;
			}

			valueOfNFTs = mintAmount * 0;
			wMintAmount = mintAmount;


			this.setState({ nftMintingAmount: mintAmount });

			this.setState({ totalValue: valueOfNFTs });
		} else {

			if (totalSupplyNFT < 1000) {

				onlyLeft = MAX_PER_WALLET - publicSale;
				console.log(onlyLeft);

				if (mintAmount > onlyLeft) {
					mintAmount = onlyLeft;
				}
				valueOfNFTs = mintAmount * this.state._cost;
				wMintAmount = mintAmount;

				this.setState({ nftMintingAmount: mintAmount });

				this.setState({ totalValue: valueOfNFTs });

			}
		}
	}

	onSubmit2 = async event => {
		event.preventDefault();

		console.log(this.state.walletAddress);

		try {
			let owner = await contract.methods.owner().call();


			if (account != owner) {


				try {

					console.log("minAmount:" + mintAmount);
					console.log("valueOfNFTs:" + valueOfNFTs);
					let preSaleMinting = mintAmount + preSaleCount;

					
					if(preSale_mint_status == true && preSaleMinting <= 200){
						this.setState({ statusError: false, statusLoading: true });
						await contract.methods.preSale(mintAmount * 1).send({ gasLimit: 285000, from: account, value: this.state.totalValue * 1 });
						this.setState({ statusLoading: false, success: true });
						console.log("Mint Amount :" + this.state.mintAmount);

					}else{
						this.setState({ statusError: false, statusLoading: true });
						await contract.methods.mint(mintAmount * 1).send({ gasLimit: 285000, from: account, value: this.state.totalValue * 1 });
						this.setState({ statusLoading: false, success: true });
						console.log("Mint Amount :" + this.state.mintAmount);						
					}
				

				} catch (err) {
					this.setState({ errorMassage: "ERROR : " + err.message, statusLoading: false, success: false, statusError: true });
					console.log(err);

				}
			} else {

				try {


					console.log("minAmount:" + mintAmount);
					console.log("valueOfNFTs:" + valueOfNFTs);


					this.setState({ statusError: false, statusLoading: true });
					await contract.methods.mint(mintAmount * 1).send({ gasLimit: 285000, from: account, value: this.state.totalValue * 0 });
					this.setState({ statusLoading: false, success: true });
					console.log("Mint Amount :" + this.state.mintAmount);

				} catch (err) {
					this.setState({ errorMassage: "ERROR : " + err.message, statusLoading: false, success: false, statusError: true });
					console.log(err);


				}






			}
		} catch (err) {

			console.log(err);

		}
	}

	walletConnect = async event => {
		event.preventDefault();

		const providerOptions = {
			walletconnect: {
				package: WalletConnectProvider, // required
				options: {
					infuraId: "bf933c3446b3464c988114813a1360ac" // required
				}
			}
		};

		const web3Modal = new Web3Modal({
			network: "mainnet", // optional
			cacheProvider: true, // optional
			providerOptions // required
		});



		const provider = await web3Modal.connect();

		//  Enable session (triggers QR Code modal)
		await provider.enable();

		const web3 = new Web3(provider);
		console.log("provider : " + provider);
		// Subscribe to accounts change
		provider.on("accountsChanged", (accounts) => {
			console.log(accounts);
		});

		// Subscribe to chainId change
		provider.on("chainChanged", (chainId) => {
			console.log(chainId);
		});

		// Subscribe to provider connection
		provider.on("connect", (info) => {
			console.log(info);
		});

		// Subscribe to provider disconnection
		provider.on("disconnect", (error) => {
			console.log(error);
		});

		// test if wallet is connected
		if (web3Modal.cachedProvider) {
			// connected now you can get accounts
			console.log("provider :" + web3Modal.cachedProvider);
			const accounts = await web3.eth.getAccounts();

			account = accounts[0];
			this.setState({ walletAddress: account });

			contract = new web3.eth.Contract(ABI, address);
			console.log("contract :" + contract);

			if (provider) {


				(async () => {


					if (web3Modal.cachedProvider != "walletconnect") {

						const chainId = 1;

						if (window.ethereum.networkVersion !== chainId) {
							try {
								await window.ethereum.request({
									method: 'wallet_switchEthereumChain',
									params: [{ chainId: web3.utils.toHex(chainId) }],
								});
							} catch (err) {
								// This error code indicates that the chain has not been added to MetaMask.
								if (err.code === 4902) {
									await window.ethereum.request({
										method: 'wallet_addEthereumChain',
										params: [
											{
												chainName: 'Ethereum Mainnet',
												chainId: web3.utils.toHex(chainId),
												nativeCurrency: { name: 'Ethereum', decimals: 18, symbol: 'ETH' },
												rpcUrls: ['https://etherscan.io'],

												/*chainName: 'Goerli Test Network',
												chainId: web3.utils.toHex(chainId),
												nativeCurrency: { name: 'Goerli Test Network', decimals: 18, symbol: 'GoerliETH' },
												rpcUrls: ['https://goerli.infura.io/v3/'],*/
											},
										],
									});
								}
							}
						}





						try {

							preSale_mint_status = await contract.methods.preSale_mint_status().call();
							this.setState({ _preSale_mint_status: preSale_mint_status });
							console.log("preSale_mint_status :" + preSale_mint_status);

							public_sale_cost = await contract.methods.publicSaleCost().call();
							this.setState({ _public_sale_cost: public_sale_cost });
							console.log("public_sale_cost :" + public_sale_cost);

							preSaleCost = await contract.methods.preSaleCost().call();
							this.setState({ _preSaleCost: preSaleCost });
							console.log("preSaleCost :" + preSaleCost);

							if(preSale_mint_status == true){
								cost = preSaleCost;
								this.setState({_cost : preSaleCost});

							}else{
								cost = public_sale_cost;
								this.setState({_cost : public_sale_cost});
								
							}

							totalSupplyNFT = await contract.methods.totalSupply().call();
							this.setState({ totalSupply: totalSupplyNFT });
							console.log("Total Supply:" + totalSupplyNFT);

							publicSale = await contract.methods.balanceOf(account).call();
							this.setState({ myNFTWallet: publicSale });

										

							MAX_PER_WALLET = await contract.methods.max_per_wallet().call();
							this.setState({ _MAX_PER_WALLET: MAX_PER_WALLET });
							console.log("MAX_PER_WALLET :" + MAX_PER_WALLET);

							public_mint_status = await contract.methods.public_mint_status().call();
							this.setState({ _public_mint_status: public_mint_status });
							console.log("public_mint_status :" + public_mint_status);

												

							preSaleLimit = await contract.methods.preSaleLimit().call();
							this.setState({ _preSaleLimit: preSaleLimit });
							console.log("preSaleLimit :" + preSaleLimit);

							preSaleCount = await contract.methods.preSaleCount().call();
							this.setState({ _preSaleCount: preSaleCount });
							console.log("preSaleCount :" + preSaleCount);							

						

							owner = await contract.methods.owner().call();
							console.log("Owner" + owner);

							if (owner == account) {
								console.log("owner : " + owner)
								onlyLeft = 1000 - totalSupplyNFT;

								if (mintAmount > onlyLeft) {
									mintAmount = onlyLeft;
								}

								valueOfNFTs = mintAmount * 0;
								wMintAmount = mintAmount;


								this.setState({ nftMintingAmount: mintAmount });

								this.setState({ totalValue: valueOfNFTs });
							} else {
								mintAmount = 1;

								if (totalSupplyNFT == 1000) {

									onlyLeft = 1000 - totalSupplyNFT;
									mintAmount = onlyLeft;
									this.setState({ msg: "SOLD OUT" });

								} else {
									mintAmount = 1;
									onlyLeft = MAX_PER_WALLET - publicSale;

									if (mintAmount > onlyLeft) {
										mintAmount = onlyLeft;
									}
									//mintAmount = onlyLeft;

									valueOfNFTs = mintAmount * this.state._cost;
									wMintAmount = mintAmount;


									this.setState({ nftMintingAmount: mintAmount });

									this.setState({ totalValue: valueOfNFTs });
								}
							}


						} catch (err) {
							console.log("err: " + err);

						}
					} else {

						const chainId = 1;

						if (WalletConnectProvider.networkVersion !== chainId) {
							try {
								await WalletConnectProvider.request({
									method: 'wallet_switchEthereumChain',
									params: [{ chainId: web3.utils.toHex(chainId) }],
								});
							} catch (err) {
								// This error code indicates that the chain has not been added to MetaMask.
								if (err.code === 4902) {
									await window.ethereum.request({
										method: 'wallet_addEthereumChain',
										params: [
											{
												chainName: 'Ethereum Mainnet',
												chainId: web3.utils.toHex(chainId),
												nativeCurrency: { name: 'Ethereum', decimals: 18, symbol: 'ETH' },
												rpcUrls: ['https://etherscan.io'],

												/*chainName: 'Goerli Test Network',
												chainId: web3.utils.toHex(chainId),
												nativeCurrency: { name: 'Goerli Test Network', decimals: 18, symbol: 'GoerliETH' },
												rpcUrls: ['https://goerli.infura.io/v3/'],*/
											},
										],
									});
								}
							}
						}




						try {
							preSale_mint_status = await contract.methods.preSale_mint_status().call();
							this.setState({ _preSale_mint_status: preSale_mint_status });
							console.log("preSale_mint_status :" + preSale_mint_status);

							public_sale_cost = await contract.methods.publicSaleCost().call();
							this.setState({ _public_sale_cost: public_sale_cost });
							console.log("public_sale_cost :" + public_sale_cost);

							preSaleCost = await contract.methods.preSaleCost().call();
							this.setState({ _preSaleCost: preSaleCost });
							console.log("preSaleCost :" + preSaleCost);

							if(preSale_mint_status == true){
								cost = preSaleCost;
								this.setState({_cost : preSaleCost});

							}else{
								cost = public_sale_cost;
								this.setState({_cost : public_sale_cost});
								
							}

							totalSupplyNFT = await contract.methods.totalSupply().call();
							this.setState({ totalSupply: totalSupplyNFT });
							console.log("Total Supply:" + totalSupplyNFT);

							publicSale = await contract.methods.balanceOf(account).call();
							this.setState({ myNFTWallet: publicSale });

							
						

							MAX_PER_WALLET = await contract.methods.max_per_wallet().call();
							this.setState({ _MAX_PER_WALLET: MAX_PER_WALLET });
							console.log("MAX_PER_WALLET :" + MAX_PER_WALLET);

							public_mint_status = await contract.methods.public_mint_status().call();
							this.setState({ _public_mint_status: public_mint_status });
							console.log("public_mint_status :" + public_mint_status);

						

							

							preSaleLimit = await contract.methods.preSaleLimit().call();
							this.setState({ _preSaleLimit: preSaleLimit });
							console.log("preSaleLimit :" + preSaleLimit);

							preSaleCount = await contract.methods.preSaleCount().call();
							this.setState({ _preSaleCount: preSaleCount });
							console.log("preSaleCount :" + preSaleCount);							

						

							owner = await contract.methods.owner().call();
							console.log("Owner" + owner);

							if (owner == account) {
								console.log("owner : " + owner)
								onlyLeft = 1000 - totalSupplyNFT;

								if (mintAmount > onlyLeft) {
									mintAmount = onlyLeft;
								}

								valueOfNFTs = mintAmount * 0;
								wMintAmount = mintAmount;


								this.setState({ nftMintingAmount: mintAmount });

								this.setState({ totalValue: valueOfNFTs });
							} else {
								mintAmount = 1;

								if (totalSupplyNFT == 1000) {

									onlyLeft = 1000 - totalSupplyNFT;
									mintAmount = onlyLeft;
									this.setState({ msg: "SOLD OUT" });

								} else {
									mintAmount = 1;
									onlyLeft = MAX_PER_WALLET - publicSale;

									if (mintAmount > onlyLeft) {
										mintAmount = onlyLeft;
									}
									//mintAmount = onlyLeft;

									valueOfNFTs = mintAmount * this.state._cost;
									wMintAmount = mintAmount;


									this.setState({ nftMintingAmount: mintAmount });

									this.setState({ totalValue: valueOfNFTs });
								}
							}


						} catch (err) {
							console.log("err: " + err);

						}
					}
				})();

				//.....................................................................//

				// Legacy providers may only have ethereum.sendAsync
				const chainId = await provider.request({
					method: 'eth_chainId'
				})

			} else {

				// if the provider is not detected, detectEthereumProvider resolves to null
				console.error('Please install a Valid Wallet');
				alert('A valid provider could not be found!');

			}

		}

	}

	walletDisconnect = async event => {
		event.preventDefault();


		const providerOptions = {
			walletconnect: {
				package: WalletConnectProvider, // required
				options: {
					infuraId: "bf933c3446b3464c988114813a1360ac" // required
				}
			}
		};

		const web3Modal = new Web3Modal({
			network: "mainnet", // optional
			cacheProvider: true, // optional
			providerOptions // required
		});



		// disconnect wallet
		web3Modal.clearCachedProvider();
		window.location.reload();

	}


	render() {

		return (

			<div class="allWrap">
				<div class="light">

					<div class="cont">

						<div class="headers">

							<div class="h1">
								<div class="connect">
									<div ><Link activeClass="" id="fontSize" to="about" spy={true} smooth={true} duration={550}>ABOUT</Link></div>
									<div ><Link activeClass="" id="fontSize" to="mint" spy={true} smooth={true} duration={550}>MINT</Link></div>
									<div ><Link activeClass="" id="fontSize" to="rm" spy={true} smooth={true} duration={550}>ROADMAP</Link></div>

								</div>

								<div class="right">
								<div class="connect2Mobile">
									{this.state.walletAddress === '' ?
										(<form onSubmit={this.walletConnect}>
											<button class="wallet2" >CONNECT WALLET</button>
										</form>) : (<form onSubmit={this.walletDisconnect}><button class="wallet2" >
											{this.state.walletAddress.slice(0, 3) + "..." + this.state.walletAddress.slice(39, 42)}</button></form>)}

								</div>
									<div class="icons">
										<div class="discord"><img class="osPic" src={os} onClick={opensea} /></div>
										<div class="discord">
											<SocialIcon class="twitter" url="https://discord.gg/pHHx7kYQRg" target="_blank" network="discord" bgColor="#2aacff" style={{ height: 30, width: 30 }} />
										</div>
										<div class="discord">
											<SocialIcon class="twitter" url="https://twitter.com/fishpricks?s=21&t=FK8Es99R5IfOy7ytr4UTtQ" target="_blank" network="twitter" bgColor="#2aacff" style={{ height: 30, width: 30 }} />
										</div>

										<div class="discord2"><img class="osPic" src={os} onClick={opensea} /></div>

										<div class="discord2">
											<SocialIcon class="twitter" url="https://discord.gg/pHHx7kYQRg" target="_blank" network="discord" bgColor="#2aacff" style={{ height: 40, width: 40 }} />
										</div>

										<div class="discord2">
											<SocialIcon class="twitter" url="https://twitter.com/fishpricks?s=21&t=FK8Es99R5IfOy7ytr4UTtQ" target="_blank" network="twitter" bgColor="#2aacff" style={{ height: 40, width: 40 }} />
										</div>

										<div class="discord3"><img class="osPic" src={os} onClick={opensea} /></div>

										<div class="discord3">
											<SocialIcon class="twitter" url="https://discord.gg/pHHx7kYQRg" target="_blank" network="discord" bgColor="#2aacff" style={{ height: 55, width: 55 }} />
										</div>

										<div class="discord3">
											<SocialIcon class="twitter" url="https://twitter.com/fishpricks?s=21&t=FK8Es99R5IfOy7ytr4UTtQ" target="_blank" network="twitter" bgColor="#2aacff" style={{ height: 55, width: 55 }} />
										</div>

										<div class="discord4"><img class="osPic" src={os} onClick={opensea} /></div>

										<div class="discord4">
											<SocialIcon class="twitter" url="https://discord.gg/pHHx7kYQRg" target="_blank" network="discord" bgColor="#2aacff" style={{ height: 75, width: 75 }} />
										</div>

										<div class="discord4">
											<SocialIcon class="twitter" url="https://twitter.com/fishpricks?s=21&t=FK8Es99R5IfOy7ytr4UTtQ" target="_blank" network="twitter" bgColor="#2aacff" style={{ height: 75, width: 75 }} />
										</div>

									</div>
									<div class="connect2">
									{this.state.walletAddress === '' ?
										(<form onSubmit={this.walletConnect}>
											<button class="wallet2" >CONNECT WALLET</button>
										</form>) : (<form onSubmit={this.walletDisconnect}><button class="wallet2" >
											{this.state.walletAddress.slice(0, 3) + "..." + this.state.walletAddress.slice(39, 42)}</button></form>)}

								</div>
								</div>

							</div>

							<div class="connectMobile">
								<div ><Link activeClass="" id="fontSize" to="about" spy={true} smooth={true} duration={550}>ABOUT</Link></div>
								<div ><Link activeClass="" id="fontSize" to="mint" spy={true} smooth={true} duration={550}>MINT</Link></div>
								<div ><Link activeClass="" id="fontSize" to="rm" spy={true} smooth={true} duration={550}>ROADMAP</Link></div>

							</div>
							<div class="vidCont">
								<video class="videoBg" src={video} autobuffer autoPlay loop muted playsInline></video></div>
						</div>

						<div class="introduction">
							<img class="middleH" src={logomiddle} />

						</div>

						<div class="container2">
							<div class="circle-container">
								<div class="circle"></div>
							</div>
							<div class="circle-container">
								<div class="circle"></div>
							</div>
							<div class="circle-container">
								<div class="circle"></div>
							</div>
							<div class="circle-container">
								<div class="circle"></div>
							</div>
							<div class="circle-container">
								<div class="circle"></div>
							</div>
							<div class="circle-container">
								<div class="circle"></div>
							</div>
							<div class="circle-container">
								<div class="circle"></div>
							</div>
							<div class="circle-container">
								<div class="circle"></div>
							</div>
							<div class="circle-container">
								<div class="circle"></div>
							</div>
							<div class="circle-container">
								<div class="circle"></div>
							</div>
							<div class="circle-container">
								<div class="circle"></div>
							</div>
							<div class="circle-container">
								<div class="circle"></div>
							</div>
							<div class="circle-container">
								<div class="circle"></div>
							</div>
							<div class="circle-container">
								<div class="circle"></div>
							</div>
							<div class="circle-container">
								<div class="circle"></div>
							</div>
							<div class="circle-container">
								<div class="circle"></div>
							</div>
							<div class="circle-container">
								<div class="circle"></div>
							</div>
							<div class="circle-container">
								<div class="circle"></div>
							</div>
							<div class="circle-container">
								<div class="circle"></div>
							</div>
							<div class="circle-container">
								<div class="circle"></div>
							</div>
							<div class="circle-container">
								<div class="circle"></div>
							</div>
							<div class="circle-container">
								<div class="circle"></div>
							</div>
							<div class="circle-container">
								<div class="circle"></div>
							</div>
							<div class="circle-container">
								<div class="circle"></div>
							</div>
							<div class="circle-container">
								<div class="circle"></div>
							</div>
							<div class="circle-container">
								<div class="circle"></div>
							</div>
							<div class="circle-container">
								<div class="circle"></div>
							</div>
							<div class="circle-container">
								<div class="circle"></div>
							</div>
							<div class="circle-container">
								<div class="circle"></div>
							</div>
							<div class="circle-container">
								<div class="circle"></div>
							</div>
							<div class="circle-container">
								<div class="circle"></div>
							</div>
							<div class="circle-container">
								<div class="circle"></div>
							</div>
							<div class="circle-container">
								<div class="circle"></div>
							</div>
							<div class="circle-container">
								<div class="circle"></div>
							</div>
							<div class="circle-container">
								<div class="circle"></div>
							</div>
							<div class="circle-container">
								<div class="circle"></div>
							</div>
							<div class="circle-container">
								<div class="circle"></div>
							</div>
							<div class="circle-container">
								<div class="circle"></div>
							</div>
							<div class="circle-container">
								<div class="circle"></div>
							</div>
							<div class="circle-container">
								<div class="circle"></div>
							</div>
							<div class="circle-container">
								<div class="circle"></div>
							</div>
							<div class="circle-container">
								<div class="circle"></div>
							</div>
							<div class="circle-container">
								<div class="circle"></div>
							</div>
							<div class="circle-container">
								<div class="circle"></div>
							</div>
							<div class="circle-container">
								<div class="circle"></div>
							</div>
							<div class="circle-container">
								<div class="circle"></div>
							</div>
							<div class="circle-container">
								<div class="circle"></div>
							</div>
							<div class="circle-container">
								<div class="circle"></div>
							</div>
							<div class="circle-container">
								<div class="circle"></div>
							</div>
							<div class="circle-container">
								<div class="circle"></div>
							</div>
							<div class="circle-container">
								<div class="circle"></div>
							</div>
							<div class="circle-container">
								<div class="circle"></div>
							</div>
							<div class="circle-container">
								<div class="circle"></div>
							</div>
							<div class="circle-container">
								<div class="circle"></div>
							</div>
							<div class="circle-container">
								<div class="circle"></div>
							</div>
							<div class="circle-container">
								<div class="circle"></div>
							</div>
							<div class="circle-container">
								<div class="circle"></div>
							</div>
							<div class="circle-container">
								<div class="circle"></div>
							</div>
							<div class="circle-container">
								<div class="circle"></div>
							</div>
							<div class="circle-container">
								<div class="circle"></div>
							</div>
							<div class="circle-container">
								<div class="circle"></div>
							</div>
							<div class="circle-container">
								<div class="circle"></div>
							</div>
							<div class="circle-container">
								<div class="circle"></div>
							</div>
							<div class="circle-container">
								<div class="circle"></div>
							</div>
							<div class="circle-container">
								<div class="circle"></div>
							</div>
							<div class="circle-container">
								<div class="circle"></div>
							</div>
							<div class="circle-container">
								<div class="circle"></div>
							</div>
							<div class="circle-container">
								<div class="circle"></div>
							</div>
							<div class="circle-container">
								<div class="circle"></div>
							</div>
							<div class="circle-container">
								<div class="circle"></div>
							</div>
							<div class="circle-container">
								<div class="circle"></div>
							</div>
							<div class="circle-container">
								<div class="circle"></div>
							</div>
							<div class="circle-container">
								<div class="circle"></div>
							</div>
							<div class="circle-container">
								<div class="circle"></div>
							</div>
							<div class="circle-container">
								<div class="circle"></div>
							</div>
							<div class="circle-container">
								<div class="circle"></div>
							</div>
							<div class="circle-container">
								<div class="circle"></div>
							</div>
							<div class="circle-container">
								<div class="circle"></div>
							</div>
							<div class="circle-container">
								<div class="circle"></div>
							</div>
							<div class="circle-container">
								<div class="circle"></div>
							</div>
							<div class="circle-container">
								<div class="circle"></div>
							</div>
							<div class="circle-container">
								<div class="circle"></div>
							</div>
							<div class="circle-container">
								<div class="circle"></div>
							</div>
							<div class="circle-container">
								<div class="circle"></div>
							</div>
							<div class="circle-container">
								<div class="circle"></div>
							</div>
							<div class="circle-container">
								<div class="circle"></div>
							</div>
							<div class="circle-container">
								<div class="circle"></div>
							</div>
							<div class="circle-container">
								<div class="circle"></div>
							</div>
							<div class="circle-container">
								<div class="circle"></div>
							</div>
							<div class="circle-container">
								<div class="circle"></div>
							</div>
							<div class="circle-container">
								<div class="circle"></div>
							</div>
							<div class="circle-container">
								<div class="circle"></div>
							</div>
							<div class="circle-container">
								<div class="circle"></div>
							</div>
							<div class="circle-container">
								<div class="circle"></div>
							</div>
							<div class="circle-container">
								<div class="circle"></div>
							</div>
							<div class="circle-container">
								<div class="circle"></div>
							</div>
							<div class="circle-container">
								<div class="circle"></div>
							</div>
							<div class="circle-container">
								<div class="circle"></div>
							</div>
							<div class="circle-container">
								<div class="circle"></div>
							</div>
							<div class="circle-container">
								<div class="circle"></div>
							</div>
						</div>
					</div>
					<Carousel />

					<Element name="about">
						<Story />
					</Element>

					<Element name="mint">
						<div class="boxWrap2Mint">
							<div class="storyH"><img class="middleM" src={mintH} /></div>

							<div class="mintDiv">
								<div class="pDiv"><img class="parrot" src={nft} /></div>
								<div class="mintCon">
									<div class="totalSupply">{this.state.totalSupply}/1000</div>
									<div class="price">
										<div>Mint Price {this.state._cost / 1000000000000000000} ETH</div>
									</div>

									<div class="minting_count_button">
										<div class="center">
											<form onSubmit={this.onSubmitMinus}>
												<button class="btnfos-0-2" type="submit">-</button>
											</form>
										</div>

										<div>
											<div class="nftminter2"><span class="nftamount">{this.state.nftMintingAmount}</span></div>
										</div>

										<div class="center">
											<form onSubmit={this.onSubmitPlus}>
												<button class="btnfos-0-2-2" type="submit">+</button>
											</form>
										</div>
									</div>

									{this.state.walletAddress === '' ?

										(<div class="mintbuttondiv">
											<form onSubmit={this.walletConnect}>
												<button class="btnfos-0-3-1">Connect</button>
											</form></div>) :
										(<div class="mintbuttondiv">
											{this.state._mintStatus === true ? (
												<div>

													<form onSubmit={this.onSubmit2}>
														<button class="btnfos-0-3-1" type="submit">
															Mint Here</button>
													</form>

												</div>
											) : (<form onSubmit={this.onSubmit2}>
												<button class="btnfos-0-3-1" type="submit">
													Mint Here</button>
											</form>)}
										</div>)}
									<div>

										{this.state.statusError ? (
											<div class="errorMessage">
												<div >Sorry, something went wrong please try again later</div>
											</div>)
											: null}

										{this.state.statusLoading ? (
											<div class="loadingContainer">
												<div>
													<div class="loadingText">Minting your Fish</div>
													<div class="loadTextSub">(this can take up to 30 seconds)</div>
												</div>
											</div>)
											: null}

										{this.state.success ? (

											<div><div class="successfully">MINTING SUCCESSFUL!</div>
												<div class="link"></div></div>)
											: null}

									</div>

								</div>
							</div>

						</div>
					</Element>

					<Element name="rm">
						<Roadmap />
					</Element>

				</div>
			</div >)
	}
}

export default App;
