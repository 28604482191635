import React, { Component, useEffect } from 'react';
import '../App.css';
import "aos/dist/aos.css";
import Aos from 'aos';
  
let ghost = 'https://smassets.pages.dev/assets/sticky.png';

const Stry = () => {
    useEffect(() => {
        Aos.init({ duration: 4000 });
    }, [])
}

let storyUrl = 'https://fishpricsassets.pages.dev/assets/about.png';

class Story extends Component {




    render() {
        return (

            <div class="boxWrap2" >

                <div class="storyH"><img class="middleS" src={storyUrl}/></div>

                <div class="about" >
                    <div class="storyCon">
                        <p class="storyCon2">FishPricks is a new Non Fungible Token (NFT) project that aims to develop unique digital representations of original artwork that will be themed within a marine environment.
                        We are planning to release multiple projects in the near future, in limited edition amounts, to promote a select community and collectibility. In addition, we will be rewarding the truehearted swabbies via random airdrops that will be fully transparent and ensure fair distribution.
                        Climb Onboard!
                        </p>                       
                   
                    </div>
 
                </div>

            </div>
        )
    }
}

export default Story;

