import React, { Component, useEffect } from 'react';
import '../App.css';
import "aos/dist/aos.css";
import Aos from 'aos';
import rmFish from '../assets/rmFish.png';
import rmFish2 from '../assets/rmF.png';
import '../bubbles.css';
import rmFish3 from '../assets/rmFish3.png';
import rmFish4 from '../assets/rmFish4.png';
import logo from '../assets/9.jpg';
 
const Stry = () => {
    useEffect(() => {
        Aos.init({ duration: 4000 });
    }, [])
}

let roadmapUrl = 'https://fishpricsassets.pages.dev/assets/roadmap.png';
 
class Utility extends Component {

    render() {
        return (

            <div class="boxWrap2Rm">
             
                <div class="storyH"><img class="middleR" src={roadmapUrl} /></div>

                <div class="rmNewMain">
                    <div class="rmNew2">
                        <div class="rmQ">Q3 2022</div>
                        <p class="rmPNew">Conceptualize and design the FishPricks project.</p>
                    </div>

                    <div class="rmNew2">
                        <div class="rmQ">Q4 2022</div>
                        <p class="rmPNew">Launch FishPricks, Landing Page, Discord, and Twitter.</p>
                    </div>

                    <div class="rmNew2">
                        <div class="rmQ">Q1 2023</div>
                        <p class="rmPNew">Design FishPricks 2 featuring Limited Editions.</p>
                    </div>

                    <div class="rmNew2">
                        <div class="rmQ">Q2 2023</div>
                        <p class="rmPNew">Launch FishPricks 2 that will contain rewards for verified FishPricks owners.</p>
                    </div>

                    <div class="rmNew2">
                        <div class="rmQ">Q3 2023</div>
                        <p class="rmPNew">Design of FishPricks 3.</p>
                    </div>
                </div>

                <img class="rmFish" src={rmFish} />
                <img class="rmFish2" src={rmFish2} />
                <img class="rmFish3" src={rmFish3} />
                <img class="rmFish4" src={rmFish4} />

                <div class="boxWrap2Footer">


                    <div class="footer">
                        <div><img class="logoF" src={logo} /></div>

                        <div class="copyright">Copyright © 2022 FishPricks™. All Rights Reserved</div>
                    </div>

                </div>
                <div class="wrapper">
                    <div><span class="dot"></span></div>
                    <div><span class="dot"></span></div>
                    <div><span class="dot"></span></div>
                    <div><span class="dot"></span></div>
                    <div><span class="dot"></span></div>
                    <div><span class="dot"></span></div>
                    <div><span class="dot"></span></div>
                    <div><span class="dot"></span></div>
                    <div><span class="dot"></span></div>
                    <div><span class="dot"></span></div>
                    <div><span class="dot"></span></div>
                    <div><span class="dot"></span></div>
                    <div><span class="dot"></span></div>
                    <div><span class="dot"></span></div>
                    <div><span class="dot"></span></div>
                </div>
            </div>
        )
    }
}

export default Utility;

